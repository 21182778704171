<template>
  <Toolbar>
    <template #left>
      <h2 class="_heading-2" v-t="'settings_page.title.preset_background'" />
    </template>

    <template #right>
      <FileUpload name="file" mode="basic" :auto="true" :customUpload="true" @uploader="upload" :maxFileSize="8000000" chooseLabel="Add background" />
    </template>
  </Toolbar>

  <div class="grid mt-4 _relative">
    <ImgPreview v-for="item in bgs.system" :key="item.id" col="col-3" :url="item.base_url" />
  </div>

  <h2 class="_heading-2 mt-6" v-t="'settings_page.title.own_background'" />
  <div class="grid mt-4 _relative">
    <ImgPreview v-for="item in bgs.own" :key="item.id" col="col-3" :url="item.base_url" :callback="() => deleteBg(item.id)" />
    <Loader isOverlay v-if="$store.state.settings.bg.isLoading" />
  </div>

</template>

<script>
import { useStore } from 'vuex'
import Toolbar from 'primevue/toolbar';
import FileUpload from 'primevue/fileupload';

import { ImgPreview, Loader } from '../../components/ui'

import { Icon } from '../../components/ui'
import { computed } from 'vue-demi';

export default {
  components: {
    Toolbar,
    FileUpload,
    ImgPreview,
    Loader,
    // Icon,
  },
  setup() {
    const store = useStore()
    const bgs = computed(() => store.getters['settings/separatedBackgrounds'])

    async function upload(params) {
      const body = {
        photo: params.files[0],
        base_photo: params.files[0],
        photo_box_photo: params.files[0],
      }
      store.dispatch('settings/submitBase', { model: 'bg', endpoint: 'companyBackgrounds', body})
    }

    async function deleteBg(params) {
      store.dispatch('settings/deleteBase', { model: 'bg', endpoint: 'companyBackground', background: params })
    }

    return {
      bgs,
      upload,
      deleteBg,
    }
  }
}
</script>

<style lang="scss" module>
</style>